import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ClaimsApiService {

  constructor(private http: HttpClient) { }
  
  getClaim(claimNumber: string, dol: string, name: string): Observable<HttpResponse<Observable<any>>> {
    const url = environment.api_domain + claimNumber;
    dol = dol ? formatDate(dol.trim(), 'yyyy-MM-dd', 'en-US') : null;
    name = name ? name.trim() :null;
    const params = new HttpParams()
      .set('dol', dol)
      .set('name', name)
    return this.http.get<Observable<any>>(
      url, { params, observe: 'response'})
  }
  
}
