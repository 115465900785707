import mod from "./index.js";

export default mod;
export const DataSet = mod.DataSet;
export const ParserError = mod.ParserError;
export const PhraseBuilder = mod.PhraseBuilder;
export const RegExpMatcher = mod.RegExpMatcher;
export const SyntaxKind = mod.SyntaxKind;
export const TextCensor = mod.TextCensor;
export const assignIncrementingIds = mod.assignIncrementingIds;
export const asteriskCensorStrategy = mod.asteriskCensorStrategy;
export const collapseDuplicatesTransformer = mod.collapseDuplicatesTransformer;
export const compareMatchByPositionAndId = mod.compareMatchByPositionAndId;
export const englishDataset = mod.englishDataset;
export const englishRecommendedBlacklistMatcherTransformers = mod.englishRecommendedBlacklistMatcherTransformers;
export const englishRecommendedTransformers = mod.englishRecommendedTransformers;
export const englishRecommendedWhitelistMatcherTransformers = mod.englishRecommendedWhitelistMatcherTransformers;
export const fixedCharCensorStrategy = mod.fixedCharCensorStrategy;
export const fixedPhraseCensorStrategy = mod.fixedPhraseCensorStrategy;
export const grawlixCensorStrategy = mod.grawlixCensorStrategy;
export const keepEndCensorStrategy = mod.keepEndCensorStrategy;
export const keepStartCensorStrategy = mod.keepStartCensorStrategy;
export const parseRawPattern = mod.parseRawPattern;
export const pattern = mod.pattern;
export const randomCharFromSetCensorStrategy = mod.randomCharFromSetCensorStrategy;
export const remapCharactersTransformer = mod.remapCharactersTransformer;
export const resolveConfusablesTransformer = mod.resolveConfusablesTransformer;
export const resolveLeetSpeakTransformer = mod.resolveLeetSpeakTransformer;
export const skipNonAlphabeticTransformer = mod.skipNonAlphabeticTransformer;
export const toAsciiLowerCaseTransformer = mod.toAsciiLowerCaseTransformer;
