import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-comment',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './comment.component.html',
  styleUrl: './comment.component.css'
})
export class CommentComponent implements OnInit {
  remainingChar = '';
  charLimit: number = 500;
  

  ngOnInit(): void {
    document.getElementById('commentBox').setAttribute("maxlength", this.charLimit.toString())
  }
}
