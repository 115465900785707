"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntervalCollection = void 0;
class IntervalCollection {
    constructor() {
        this.dirty = false;
        this.intervals = [];
    }
    insert(lowerBound, upperBound) {
        this.intervals.push([lowerBound, upperBound]);
        this.dirty = true;
    }
    query(lowerBound, upperBound) {
        if (this.intervals.length === 0)
            return false;
        if (this.dirty) {
            this.dirty = false;
            // Sort by lower bound.
            this.intervals.sort(
            /* istanbul ignore next: not possible to write a robust test for this */
            (a, b) => (a[0] < b[0] ? -1 : b[0] < a[0] ? 1 : 0));
        }
        for (const interval of this.intervals) {
            // Since the intervals are sorted by lower bound, if we see an
            // interval with a lower bound greater than the target, we can skip
            // checking all the ones after it as it's impossible that they fully
            // contain the target interval.
            if (interval[0] > lowerBound)
                break;
            if (interval[0] <= lowerBound && upperBound <= interval[1])
                return true;
        }
        return false;
    }
    values() {
        return this.intervals.values();
    }
    [Symbol.iterator]() {
        return this.values();
    }
}
exports.IntervalCollection = IntervalCollection;
