"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParserError = void 0;
/**
 * Custom error thrown by the parser when syntactical errors are detected.
 */
class ParserError extends Error {
    constructor(message, line, column) {
        super(`${line}:${column}: ${message}`);
        this.name = 'ParserError';
        this.line = line;
        this.column = column;
    }
}
exports.ParserError = ParserError;
