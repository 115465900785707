import { Component, OnInit } from '@angular/core';
import { ClaimInformationComponent } from '../../components/claim-information/claim-information.component';
import { LossDetailsComponent } from '../../components/loss-details/loss-details.component';
import { PaymentsComponent } from '../../components/payments/payments.component';
import { ClaimHandlerComponent } from '../../components/claim-handler/claim-handler.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-claim-found',
  standalone: true,
  imports: [ClaimInformationComponent, LossDetailsComponent, PaymentsComponent, ClaimHandlerComponent],
  templateUrl: './claim-found.component.html',
  styleUrl: './claim-found.component.css'
})

export class ClaimFoundComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit() {}

  goToSearch(): void {
    this.router.navigate(['/search-page']);
  }

}
