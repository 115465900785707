import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, throwError, of } from 'rxjs';
import { inject } from '@angular/core';
import { ClaimsApiService } from './claims-api.service';
import { Claim } from './claim.module';
import { HttpResponse } from '@angular/common/http';
import { SharedService } from '../shared.service';

export const ClaimResolver: ResolveFn<Observable<HttpResponse<Claim[]>>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  api: ClaimsApiService = inject(ClaimsApiService),
  
  sharedService: SharedService = inject (SharedService)
  ): Observable<any> => {
    return api.getClaim(sharedService.claimNumber, sharedService.dol, sharedService.name).pipe(
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message)
          }
          else {
            if (error.status === 400) {
              return of(throwError(() => new Error('Claim number entered does not find a match.')))
            }
            else if (error.status === 404) {
              return of(throwError(() => new Error("Parameters don't match claim number")))
            }
            else if (error.status === 500) {
              return of(throwError(() => new Error('Something has gone wrong, we apologize for the inconvenience. Please try again later or contact the claim handler.')))
            }
          }
          return of(throwError(() => new Error('Error fetching claim data')))
        })
      )
        
    }
  
    
