import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fax',
  standalone: true
})
export class FaxPipe implements PipeTransform {

  transform(value: any, areaCode: any, extension: any ): String {
    var fax;
    if (value) {
      fax = extension ? "(" + areaCode + ") " + value.slice(0,3) + '-' + value.slice(3,7) + " x" + extension : "(" + areaCode + ") " + value.slice(0,3) + '-' + value.slice(3,7)
    } else {
      fax = 'Not Available'
    }
    return fax;
  }

}
