import { Component, OnInit } from '@angular/core';
import { ClaimsApiService } from '../../services/Claim/claims-api.service';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-claim-information',
  standalone: true,
  imports: [CommonModule],
  providers: [ClaimsApiService],
  templateUrl: './claim-information.component.html',
  styleUrl: './claim-information.component.css'
})
export class ClaimInformationComponent implements OnInit{
  claimNumber: string;
  dateOfLoss: string;
  name: string;
  errorMessage: string;
  error: boolean
  
  constructor(private route: ActivatedRoute, private api: ClaimsApiService, private shared: SharedService) {}

  ngOnInit(): void {
    this.claimNumber = this.shared.claimNumber
    this.dateOfLoss = formatDate(this.shared.dol, 'MM-dd-yyyy', 'en-US');
    this.name = this.shared.name
    /*
    
    this.route.data.subscribe(data => { 
        try {
          this.dateOfLoss = data['httpResponse'].body.payload.date_of_loss;
        }
        catch {
          data['httpResponse'].subscribe()
          this.error = true;
          this.errorMessage = 'Something went wrong. Please try again later.';
        }
      });
      */
  }
}
