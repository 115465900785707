import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public claimNumber: string;
  public dol: string
  public name: string

  constructor() { }

  changeClaimNumber(claimNumber: string) {
    this.claimNumber = claimNumber;
  }

  changeDol(dol: string) {
    this.dol = formatDate(dol, 'yyyy-MM-dd', 'en-US');
  }

  changeName(name: string) {
    this.name = name;
  }
}