"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveLeetSpeakTransformer = void 0;
const remap_characters_1 = require("../remap-characters");
const dictionary_1 = require("./dictionary");
/**
 * Creates a transformer that maps leet-speak characters to their normalized
 * equivalent. For example, `$` becomes `s` when using this transformer.
 *
 * **Application order**
 *
 * It is recommended that this transformer be applied near the start of the
 * transformer chain, but after similar transformers that map characters to
 * other characters, such as the [[resolveConfusablesTransformer | transformer
 * that resolves confusable Unicode characters]].
 *
 * @example
 * ```typescript
 * const transformer = resolveLeetSpeakTransformer();
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @returns A container holding the transformer, which can then be passed to the
 * [[RegExpMatcher]].
 */
function resolveLeetSpeakTransformer() {
    return (0, remap_characters_1.remapCharactersTransformer)(dictionary_1.dictionary);
}
exports.resolveLeetSpeakTransformer = resolveLeetSpeakTransformer;
