import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Payload } from '../../classes/payload.model';
import { AddressPipe } from '../../pipes/address.pipe';

@Component({
  selector: 'app-loss-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loss-details.component.html',
  styleUrl: './loss-details.component.css'
})
export class LossDetailsComponent implements OnInit{
  status: string;
  address;
  lossLocation: object;
  errorMessage: string;
  error: boolean;

  constructor(private payload: Payload, private addressPipe: AddressPipe) {}

  ngOnInit(): void {
    try {
      this.status = this.payload.getStatus();
      this.lossLocation = this.payload.getLossLocation();
      this.address = this.addressPipe.transform(this.lossLocation['street'], this.lossLocation['city'], this.lossLocation['state'], this.lossLocation['zip_code'])     
    } catch{ 
        this.error = true;
        this.errorMessage = 'Something went wrong. Please try again later.';
    }
  }
}