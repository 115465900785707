import { Component, OnInit } from '@angular/core';
import { ClaimInformationComponent } from '../../components/claim-information/claim-information.component';
import { LossDetailsComponent } from '../../components/loss-details/loss-details.component';
import { PaymentsComponent } from '../../components/payments/payments.component';
import { ClaimHandlerComponent } from '../../components/claim-handler/claim-handler.component';
import { Router } from '@angular/router';
import { CommentComponent } from '../../components/comment/comment.component';
import { Payload } from '../../classes/payload.model';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { enableComment } from '../../models/constants';
import { FileUploadComponent } from '../../components/file-upload/file-upload.component';
import { DataAnalyticsService } from '../../services/data-analytics.service';

@Component({
  selector: 'app-claim-found',
  standalone: true,
  imports: [ClaimInformationComponent, LossDetailsComponent, PaymentsComponent, ClaimHandlerComponent, CommentComponent, FileUploadComponent, CommonModule],
  templateUrl: './claim-found.component.html',
  styleUrl: './claim-found.component.css'
})

export class ClaimFoundComponent implements OnInit {
  show: boolean;
  enableComment = enableComment
  constructor(private router: Router,private payload: Payload,private dataTag: DataAnalyticsService) {}

  ngOnInit() {
    const extId = this.payload.getExtId()
    this.dataTag.getAnalyticsTaggingExtId(extId)
    this.dataTag.getAnalyticsTagging('claim-view-results')
    const status = this.payload.getStatus();
    this.show = true ? status != 'Closed' : false;
  }

  goToSearch(): void {
    this.router.navigate(['/search-page']);
  }

}
