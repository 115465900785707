"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remapCharactersTransformer = void 0;
const Char_1 = require("../../util/Char");
const CharacterIterator_1 = require("../../util/CharacterIterator");
const Transformers_1 = require("../Transformers");
/**
 * Maps certain characters to other characters, leaving other characters
 * unchanged.
 *
 * **Application order**
 *
 * It is recommended that this transformer be applied near the start of the
 * transformer chain.
 *
 * @example
 * ```typescript
 * // Transform 'a' to 'b'.
 * const transformer = remapCharactersTransformer({ 'b': 'a' });
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @example
 * ```typescript
 * // Transform '🅱️' to 'b', and use a map instead of an object as the argument.
 * const transformer = remapCharactersTransformer(new Map([['b', '🅱️']]));
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @example
 * ```typescript
 * // Transform '🇴' and '0' to 'o'.
 * const transformer = remapCharactersTransformer({ o: '🇴0' });
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @param mapping - A map/object mapping certain characters to others.
 * @returns A container holding the transformer, which can then be passed to the
 * [[RegExpMatcher]].
 * @see [[resolveConfusablesTransformer|  Transformer that handles confusable Unicode characters]]
 * @see [[resolveLeetSpeakTransformer | Transformer that handles leet-speak]]
 */
function remapCharactersTransformer(mapping) {
    const map = createOneToOneMap(mapping);
    return (0, Transformers_1.createSimpleTransformer)((c) => map.get(c) ?? c);
}
exports.remapCharactersTransformer = remapCharactersTransformer;
function createOneToOneMap(mapping) {
    const map = new Map();
    const iterable = mapping instanceof Map ? mapping.entries() : Object.entries(mapping);
    for (const [original, equivalents] of iterable) {
        const originalChar = (0, Char_1.getAndAssertSingleCodePoint)(original);
        const iter = new CharacterIterator_1.CharacterIterator(equivalents);
        for (const equivalent of iter)
            map.set(equivalent, originalChar);
    }
    return map;
}
