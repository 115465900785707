<div class="-oneX-container" style="padding: 0">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">

        <div *ngIf="isLoading" style="display: flex; justify-content: center;" class="-vertical-center" id="overlay" style="margin: 0">
            <div class="-oneX-page-loader" tabindex="-1">
                <div className="-oneX-loader-icon" aria-hidden="true"></div>
                <svg class="-oneX-large" viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="45"></circle>
                </svg>
            </div>
        </div>
        

        <div class="-oneX-row -oneX-justify-content-center topPadding" >
            <div class="-oneX-col-6">
                <div id="colclaimnum" aria-label="Claim Number"
                    [ngClass]="{'-oneX-widget--err': isInvalidClaimNumber}">
                    <div class="form-group has-feedback" required>
                        <fieldset class="-oneX-fieldset">
                            <label for="conv-textfield" class="-oneX-textfield__label">Claim Number<span>&#42;</span></label>
                            <input id="claimInput"
                                            class="-oneX-textfield--conversational-input -oneX-textfield--control"
                                            aria-required="true" type="text" style="text-transform: uppercase;" minlength="11"
                                            autocomplete="off" aria-invalid="false"formControlName="claimNumber" 
                                            aria-describedby="claimNumberAlert" (blur)="errorInClaimNumber()" minlength="11"
                                            data-formatter="custom" placeholder="__-____-___" pattern="\d{2}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{3}"/>
                            <span class="-oneX-contextual-help-container">
                                <button id="claimHelp" aria-label="Claim Number Help"
                                    class="-oneX-contextual-help -oneX-btn-control" aria-expanded="false" tabindex="-1"
                                    type="button">
                                    <div class="-oneX-icon--controls--question"></div>
                                    <div class="-oneX-notch"></div>
                                </button>
                                <span id="claimHelp" id="claimnumberHelpTextContext" tabindex="0"
                                    class="-oneX-contextual-help--text">
                                    <div id="claimnumberHelpText"> Enter the State Farm Claim Number
                                        <br> <br>
                                        <div style="font-size: 20px;">99-XXXX-XXX </div>
                                        <br> X - Alphanumerical (0-9,A-Z) <br> 9 - Numerical (0-9)
                                    </div>
                                    <button aria-label="Close Help" class="-oneX-close-X-button">
                                        <span class="-oneX-icon--close">
                                        </span>
                                    </button>
                                </span>
                            </span>
                        </fieldset>
                        <div id="claim-err" class="-oneX-widget__err-text" aria-live="assertive"
                            *ngIf="isInvalidClaimNumber">{{claimErrorMsg}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="-oneX-row -oneX-justify-content-center">
            <div class="-oneX-col-6">
                <div id="coldate" aria-labelledby="dol" aria-label="Date of Loss (MM-DD-YYYY)"
                    [ngClass]="{'-oneX-widget--err': isInvalidDOL}">
                    <div class="form-group has-feedback" required>
                        <fieldset class="-oneX-fieldset">
                            <label for="dateInput" class="-oneX-textfield__label">Date of Loss<span>&#42;</span></label>
                            <input class="-oneX-textfield--conversational-input -oneX-textfield--control"
                                aria-invalid="false" type="text" id="dateInput" name="dol" autocomplete="off"
                                data-formatter="date" aria-required="true" maxlength="10" (blur)="errorInDateOfLoss()"
                                aria-describedby="dateOfLossAlert" formControlName="dateOfLoss" />
                            <button tabindex="-1" class="-oneX-btn-control" type="button">
                                <div tabindex="-1" aria-label="datepicker" role="img"
                                    class="-oneX-icon--controls--calendar"></div>
                            </button>
                        </fieldset>
                        <div id="dol-err" class="-oneX-widget__err-text" aria-live="assertive" *ngIf="isInvalidDOL">
                            {{dateOfLossErrorMsg}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="-oneX-row -oneX-justify-content-center">
            <div class="-oneX-col-6">
                <div id="colname" [ngClass]="{'-oneX-widget--err':isInvalidName}" aria-labelledby="name"
                    aria-label="Name">
                    <div class="form-group has-feedback">
                        <fieldset class="-oneX-fieldset">
                            <label for="nameInput" class="-oneX-textfield__label">Name<span>&#42;</span></label>
                            <input id="nameInput"
                                class="-oneX-textfield--conversational-input -oneX-textfield--control"
                                aria-invalid="false" autocomplete="off" type="text" aria-required="true"
                                formControlName="name" (blur)="errorInName()"
                                aria-describedby="NameAlert" />
                            <span class="-oneX-contextual-help-container -oneX-closed">
                                <button aria-label="Name Help" role="button"
                                    class="-oneX-contextual-help -oneX-btn-control" tabindex="-1" type="button">
                                    <div class="-oneX-icon--controls--question"></div>
                                    <div class="-oneX-notch"></div>
                                </button>
                                <span id="NameHelp" tabindex="0" class="-oneX-contextual-help--text">
                                    <div id="NameHelp1"> Please enter the policyholder's last name or organization name.
                                        <br> First two characters are sufficient.
                                    </div>
                                    <button aria-label="Close Help" class="-oneX-close-X-button">
                                        <span class="-oneX-icon--close">
                                        </span>
                                    </button>
                                </span>
                            </span>
                        </fieldset>
                        <div id="name-err" class="-oneX-widget__err-text" aria-live="assertive"
                            *ngIf="isInvalidName">{{nameErrorMsg}}</div>
                    </div>
                </div>
            </div>
        </div>
        

        <br>
        <div class="-oneX-row -oneX-justify-content-center">
            <button tabindex="0" id="buttonid" class="-oneX-btn-primary -oneX-btn-fit-content"
                type="submit">Submit</button>
        </div>
    

    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>

