"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collapseDuplicatesTransformer = void 0;
const Char_1 = require("../../util/Char");
const Transformers_1 = require("../Transformers");
const transformer_1 = require("./transformer");
/**
 * Creates a transformer that collapses duplicate characters. This is useful for
 * detecting variants of patterns in which a character is repeated to bypass
 * detection.
 *
 * As an example, the pattern `hi` does not match `hhiii` by default, as the
 * frequency of the characters does not match. With this transformer, `hhiii`
 * would become `hi`, and would therefore match the pattern.
 *
 * **Application order**
 *
 * It is recommended that this transformer be applied after all other
 * transformers. Using it before other transformers may have the effect of not
 * catching duplicates of certain characters that were originally different but
 * became the same after a series of transformations.
 *
 * **Warning**
 *
 * This transformer should be used with caution, as while it can make certain
 * patterns match text that wouldn't have been matched before, it can also go
 * the other way. For example, the pattern `hello` clearly matches `hello`, but
 * with this transformer, by default, `hello` would become `helo` which does
 * _not_ match. In this cases, the `customThresholds` option can be used to
 * allow two `l`s in a row, making it leave `hello` unchanged.
 *
 * @example
 * ```typescript
 * // Collapse runs of the same character.
 * const transformer = collapseDuplicatesTransformer();
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @example
 * ```typescript
 * // Collapse runs of characters other than 'a'.
 * const transformer = collapseDuplicatesTransformer({ customThresholds: new Map([['a', Infinity]]) });
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @param options - Options for the transformer.
 * @returns A container holding the transformer, which can then be passed to the
 * [[RegExpMatcher]].
 */
function collapseDuplicatesTransformer({ defaultThreshold = 1, customThresholds = new Map(), } = {}) {
    const map = createCharacterToThresholdMap(customThresholds);
    return (0, Transformers_1.createStatefulTransformer)(() => new transformer_1.CollapseDuplicatesTransformer({ defaultThreshold, customThresholds: map }));
}
exports.collapseDuplicatesTransformer = collapseDuplicatesTransformer;
function createCharacterToThresholdMap(customThresholds) {
    const map = new Map();
    for (const [str, threshold] of customThresholds) {
        if (threshold < 0)
            throw new RangeError('Expected all thresholds to be non-negative.');
        const char = (0, Char_1.getAndAssertSingleCodePoint)(str);
        map.set(char, threshold);
    }
    return map;
}
