"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toAsciiLowerCaseTransformer = void 0;
const Char_1 = require("../../util/Char");
const Transformers_1 = require("../Transformers");
/**
 * Creates a transformer that changes all ASCII alphabet characters to
 * lower-case, leaving other characters unchanged.
 *
 * **Application order**
 *
 * It is recommended that this transformer be applied near the end of the
 * transformer chain. Using it before other transformers may have the effect of
 * making its changes useless as transformers applied after produce characters
 * of varying cases.
 *
 * @returns A container holding the transformer, which can then be passed to the
 * [[RegExpMatcher]].
 */
function toAsciiLowerCaseTransformer() {
    return (0, Transformers_1.createSimpleTransformer)((c) => ((0, Char_1.isUpperCase)(c) ? (0, Char_1.invertCaseOfAlphabeticChar)(c) : c));
}
exports.toAsciiLowerCaseTransformer = toAsciiLowerCaseTransformer;
