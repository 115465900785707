"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignIncrementingIds = void 0;
/**
 * Assigns incrementing IDs to the patterns provided, starting with 0. It is
 * useful if you have a list of patterns to match against but don't care about
 * identifying which pattern matched.
 *
 * @example
 * ```typescript
 * const matcher = new RegExpMatcher({
 *  ...,
 *  blacklistedTerms: assignIncrementingIds([
 *      pattern`f?uck`,
 *      pattern`|shit|`,
 *  ]),
 * });
 * ```
 * @param patterns - List of parsed patterns.
 * @returns A list of blacklisted terms with valid IDs which can then be passed
 * to the [[RegExpMatcher]].
 */
function assignIncrementingIds(patterns) {
    let currentId = 0;
    return patterns.map((pattern) => ({ id: currentId++, pattern }));
}
exports.assignIncrementingIds = assignIncrementingIds;
