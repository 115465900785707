import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address',
  standalone: true
})
export class AddressPipe implements PipeTransform {

  transform(value: any, city: any, state: any, zip: any): String {
    const address = value + "\n" + city + ", " + state + " " + zip
    return address;
  }

}
