import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'name',
  standalone: true
})
export class NamePipe implements PipeTransform {

  constructor (private titleCasePipe: TitleCasePipe) {}
  transform(name: any, last: any): String {
    var fullName;
    if (name && last){
      fullName = this.titleCasePipe.transform(name) + " " + this.titleCasePipe.transform(last)
    } else if (name && !last){
      fullName = this.titleCasePipe.transform(name)
    } else if (!name && last){
      fullName = this.titleCasePipe.transform(last)
    }
    return fullName;
  }

}
