import { Routes } from '@angular/router';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { ClaimFoundComponent } from './pages/claim-found/claim-found.component';
import { ClaimResolver } from './services/Claim/claim-resolver.resolver';

export const routes: Routes = [
    { path: 'search-page', component: SearchPageComponent},
    { path: '', redirectTo: '/search-page', pathMatch: 'full' },
    { path: 'claim-found', component: ClaimFoundComponent, resolve: { httpResponse: ClaimResolver}}
];
