import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class Request {
    private claimNumber;
    private dol;
    private name;

    getClaimNumber(){
        return this.claimNumber;
    }

    setClaimNumber(claimNumber){
        this.claimNumber = claimNumber;
    }

    getDol(){
        return this.dol;
    }

    setDol(dol){
        this.dol = dol;
    }

    getName(){
        return this.name;
    }

    setName(name){
        this.name = name;
    }
}
