"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAndAssertSingleCodePoint = exports.invertCaseOfAlphabeticChar = exports.isUpperCase = exports.isLowerCase = exports.isAlphabetic = exports.isDigit = exports.isWordChar = exports.convertSurrogatePairToCodePoint = exports.isLowSurrogate = exports.isHighSurrogate = void 0;
function isHighSurrogate(char) {
    return 55296 /* CharacterCode.HighSurrogateStart */ <= char && char <= 56319 /* CharacterCode.HighSurrogateEnd */;
}
exports.isHighSurrogate = isHighSurrogate;
function isLowSurrogate(char) {
    return 56320 /* CharacterCode.LowSurrogateStart */ <= char && char <= 57343 /* CharacterCode.LowSurrogateEnd */;
}
exports.isLowSurrogate = isLowSurrogate;
// See https://unicodebook.readthedocs.io/unicode_encodings.html#utf-16-surrogate-pairs.
function convertSurrogatePairToCodePoint(highSurrogate, lowSurrogate) {
    return ((highSurrogate - 55296 /* CharacterCode.HighSurrogateStart */) * 0x400 +
        lowSurrogate -
        56320 /* CharacterCode.LowSurrogateStart */ +
        0x10000);
}
exports.convertSurrogatePairToCodePoint = convertSurrogatePairToCodePoint;
function isWordChar(char) {
    return isDigit(char) || isAlphabetic(char);
}
exports.isWordChar = isWordChar;
function isDigit(char) {
    return 48 /* CharacterCode.Zero */ <= char && char <= 57 /* CharacterCode.Nine */;
}
exports.isDigit = isDigit;
function isAlphabetic(char) {
    return isLowerCase(char) || isUpperCase(char);
}
exports.isAlphabetic = isAlphabetic;
function isLowerCase(char) {
    return 97 /* CharacterCode.LowerA */ <= char && char <= 122 /* CharacterCode.LowerZ */;
}
exports.isLowerCase = isLowerCase;
function isUpperCase(char) {
    return 65 /* CharacterCode.UpperA */ <= char && char <= 90 /* CharacterCode.UpperZ */;
}
exports.isUpperCase = isUpperCase;
// Input must be a lower-case or upper-case ASCII alphabet character.
function invertCaseOfAlphabeticChar(char) {
    return char ^ 0x20;
}
exports.invertCaseOfAlphabeticChar = invertCaseOfAlphabeticChar;
// Asserts that the string is comprised of one and only one code point,
// then returns said code point.
function getAndAssertSingleCodePoint(str) {
    if ([...str].length !== 1)
        throw new RangeError(`Expected the input string to be one code point in length.`);
    return str.codePointAt(0);
}
exports.getAndAssertSingleCodePoint = getAndAssertSingleCodePoint;
