import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  standalone: true
})
export class PhonePipe implements PipeTransform {

  transform(value: any, areaCode: any, extension: any): String {
    var phone;
    if (value){
      phone = extension ? "(" + areaCode + ") " + value.slice(0,3) + '-' + value.slice(3,7) + " x" + extension : "(" + areaCode + ") " + value.slice(0,3) + '-' + value.slice(3,7)
    } else {
      phone = 'Not Available'
    }
    return phone;
  }

}
