"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStatefulTransformer = exports.createSimpleTransformer = void 0;
/**
 * Creates a container holding the transformer function provided. Simple
 * transformers are suitable for stateless transformations, e.g., a
 * transformation that maps certain characters to others. For transformations
 * that need to keep around state, see `createStatefulTransformer`.
 *
 * @example
 * ```typescript
 * function lowercaseToUppercase(char) {
 *  return isLowercase(char) ? char - 32 : char;
 * }
 *
 * const transformer = createSimpleTransformer(lowercaseToUppercase);
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @example
 * ```typescript
 * function ignoreAllNonDigitChars(char) {
 *  return isDigit(char) ? char : undefined;
 * }
 *
 * const transformer = createSimpleTransformer(ignoreAllNonDigitChars);
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @param transformer - Function that applies the transformation. It should
 * accept one argument, the input character, and return the transformed
 * character. A return value of `undefined` indicates that the character should
 * be ignored.
 * @returns A container holding the transformer, which can then be passed to the
 * [[RegExpMatcher]].
 */
function createSimpleTransformer(transformer) {
    return { type: 0 /* TransformerType.Simple */, transform: transformer };
}
exports.createSimpleTransformer = createSimpleTransformer;
/**
 * Creates a container holding the stateful transformer. Stateful transformers
 * are objects which satisfy the `StatefulTransformer` interface. They are
 * suitable for transformations that require keeping around some state regarding
 * the characters previously transformed in the text.
 *
 * @example
 * ```typescript
 * class IgnoreDuplicateCharactersTransformer implements StatefulTransformer {
 *  private lastChar = -1;
 *
 *  public transform(char: number) {
 *      if (char === this.lastChar) return undefined;
 *      this.lastChar = char;
 *      return char;
 *  }
 *
 *  public reset() {
 *      this.lastChar = -1;
 *  }
 * }
 *
 * const transformer = createStatefulTransformer(() => new IgnoreDuplicateCharactersTransformer());
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @param factory A function that returns an instance of the stateful
 * transformer.
 * @returns A container holding the stateful transformer, which can then be
 * passed to the [[RegExpMatcher]].
 */
function createStatefulTransformer(factory) {
    return { type: 1 /* TransformerType.Stateful */, factory };
}
exports.createStatefulTransformer = createStatefulTransformer;
