
import { Component, Input, OnInit, } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { claimNumberValidator, dateOfLossValidator, futureDateValidator } from '../../models/validators';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.css'
})
export class SearchComponent implements OnInit {
  @Input() claimNumber = '';
  @Input() dol = '';
  @Input() name = '';

  isInvalidClaimNumber;
  isInvalidDOL = false;
  isInvalidName = false;
  isLoading: boolean = false;

  claimErrorMsg: string;
  dateOfLossErrorMsg: string;
  nameErrorMsg: string;
  searchForm = new FormGroup({
    claimNumber: new FormControl(this.claimNumber, { validators: [Validators.required, Validators.minLength(11), claimNumberValidator()] }),
    dateOfLoss: new FormControl(this.dol, { validators: [Validators.required, Validators.minLength(10), dateOfLossValidator(), futureDateValidator()] }),
    name: new FormControl(this.name, { validators: Validators.required })
  })

  constructor(private router: Router,
    private sharedService: SharedService
  ){}

  ngOnInit() {
    if (!localStorage.getItem('foo')) { 
      localStorage.setItem('foo', 'no reload') 
      location.reload() 
    } else {
      localStorage.removeItem('foo') 
    }
  }


  errorInClaimNumber() {
    if (this.searchForm.controls.claimNumber.errors?.['required']) {
      this.isInvalidClaimNumber = true;
      this.claimErrorMsg = 'Claim number is a required field.'
      document.getElementById('claimInput').setAttribute('aria-invalid', 'true')
    } else if (this.searchForm.controls.claimNumber.errors?.['minlength']) {
      this.isInvalidClaimNumber = true;
      this.claimErrorMsg = 'Claim number is 9 digits.'
      document.getElementById('claimInput').setAttribute('aria-invalid', 'true')
    } else if (this.searchForm.controls.claimNumber.errors?.['incorrectFormat']) {
      this.isInvalidClaimNumber = true;
      this.claimErrorMsg = 'Incorrect format.  Enter a valid Claim Number in 99-XXXX-XXX format.'
      document.getElementById('claimInput').setAttribute('aria-invalid', 'true')
    } else {
      this.isInvalidClaimNumber = false;
      document.getElementById('claimInput').setAttribute('aria-invalid', 'false')
    }
  }

  errorInDateOfLoss() {
    if (this.searchForm.controls.dateOfLoss.errors?.['required']) {
      this.isInvalidDOL = true;
      this.dateOfLossErrorMsg = "Date of Loss is a required field. Enter a valid Date of Loss in MM-DD-YYYY format.";
      document.getElementById('dateInput').setAttribute('aria-invalid', 'true')
    } else if (this.searchForm.controls.dateOfLoss.errors?.['minlength']) {
      this.isInvalidDOL = true;
      this.dateOfLossErrorMsg = "Incorrect format. Enter a valid Date of Loss in MM-DD-YYYY format."
      document.getElementById('dateInput').setAttribute('aria-invalid', 'true')
    } else if (this.searchForm.controls.dateOfLoss.errors?.['incorrectDate']) {
      this.isInvalidDOL = true;
      this.dateOfLossErrorMsg = "Incorrect format. Enter a valid Date of Loss in MM-DD-YYYY format."
      document.getElementById('dateInput').setAttribute('aria-invalid', 'true')
    } else if (this.searchForm.controls.dateOfLoss.errors?.['futureDate']) {
      this.isInvalidDOL = true;
      this.dateOfLossErrorMsg = "Entered date is in the future."
      document.getElementById('dateInput').setAttribute('aria-invalid', 'true')
    } else {
      this.isInvalidDOL = false;
      document.getElementById('dateInput').setAttribute('aria-invalid', 'false')
    }
  }

  errorInName() {
    if (this.searchForm.controls.name.errors?.['required']) {
      this.isInvalidName = true;
      this.nameErrorMsg = "Name is a required field. Enter at least the first 2 characters of the policyholder's last name or organization name."
      document.getElementById('nameInput').setAttribute('aria-invalid', 'true')
    } else {
      this.isInvalidName = false;
      document.getElementById('nameInput').setAttribute('aria-invalid', 'false')
    }
  }
  onSubmit() {
    if (this.searchForm.invalid) {
      this.errorInClaimNumber()
      this.errorInDateOfLoss()
      this.errorInName()
    }
    if (!this.searchForm.invalid) {
      let claimNumber = this.searchForm.controls.claimNumber.value;
      let dol = this.searchForm.controls.dateOfLoss.value;
      let name = this.searchForm.controls.name.value;
      this.sharedService.changeClaimNumber(claimNumber);
      this.sharedService.changeDol(dol);
      this.sharedService.changeName(name);
      this.isLoading = true;
      this.goToSearch()
    }
  }

  

  goToSearch(): void{
    this.router.navigate(['/claim-found']);
  }



  

}
