<div class="-oneX-container">
    <div class="-oneX-heading--h3">Claim Handler</div>
      <br>
        <div class="-oneX-container">
          <div class="-oneX-container">
            <div class="-oneX-row" *ngIf="!error"> <!--Remove ngIf if don't want-->
                <div tabindex="-1" class="-oneX-col-md-2 -oneX-col-lg-4" aria-labelledby="name">
                  <div tabindex="0" id="nameField" class="-oneX-body--intro">Name: </div>
                  <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1">{{name}}</div> <!--Move ngIf here if want Name:-->
                </div>

                <div tabindex="-1" class="-oneX-col-md-2 -oneX-col-lg-4" aria-labelledby="phone">
                  <div tabindex="0" id="phoneField" class="-oneX-body--intro"> Phone: </div>
                  <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1">{{phone}}</div> <!--Move ngIf here if want Phone:-->
                </div>
            </div>
            <br>
            <div class="-oneX-row">
                <div tabindex="-1" class="-oneX-col-md-2 -oneX-col-lg-4" aria-labelledby="mailingAddress">
                  <div tabindex="0" id="mailingAddressField" class="-oneX-body--intro"> Mailing Address: </div>
                  <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1">{{street}}</div>
                  <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1">{{mailingAddress}}</div>
                  <div tabindex="0" id="mailingAddress"> </div>
                </div>

                <div tabindex="-1" class="-oneX-col-md-2 -oneX-col-lg-4" aria-labelledby="fax">
                  <div tabindex="0" id="faxField" class="-oneX-body--intro"> Fax: </div>
                  <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1">{{fax}}</div>
                  <div tabindex="0" id="fax"> </div>
                </div>
              </div>
          </div>
      </div>
  </div>