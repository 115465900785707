"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveConfusablesTransformer = void 0;
const remap_characters_1 = require("../remap-characters");
const confusables_1 = require("./confusables");
/**
 * Creates a transformer that maps confusable Unicode characters to their
 * normalized equivalent. For example, `⓵`, `➊`, and `⑴` become `1` when using
 * this transformer.
 *
 * **Application order**
 *
 * It is recommended that this transformer be applied near the start of the
 * transformer chain.
 *
 * @example
 * ```typescript
 * const transformer = resolveConfusablesTransformer();
 * const matcher = new RegExpMatcher({ ..., blacklistMatcherTransformers: [transformer] });
 * ```
 * @returns A container holding the transformer, which can then be passed to the
 * [[RegExpMatcher]].
 */
function resolveConfusablesTransformer() {
    return (0, remap_characters_1.remapCharactersTransformer)(confusables_1.confusables);
}
exports.resolveConfusablesTransformer = resolveConfusablesTransformer;
