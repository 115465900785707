"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./censor/BuiltinStrategies"), exports);
__exportStar(require("./censor/TextCensor"), exports);
__exportStar(require("./dataset/DataSet"), exports);
__exportStar(require("./matcher/regexp/RegExpMatcher"), exports);
__exportStar(require("./matcher/BlacklistedTerm"), exports);
__exportStar(require("./matcher/MatchPayload"), exports);
__exportStar(require("./matcher/Matcher"), exports);
__exportStar(require("./pattern/Nodes"), exports);
__exportStar(require("./pattern/ParserError"), exports);
__exportStar(require("./pattern/Pattern"), exports);
__exportStar(require("./preset/english"), exports);
__exportStar(require("./transformer/collapse-duplicates"), exports);
__exportStar(require("./transformer/remap-characters"), exports);
__exportStar(require("./transformer/resolve-confusables"), exports);
__exportStar(require("./transformer/resolve-leetspeak"), exports);
__exportStar(require("./transformer/skip-non-alphabetic"), exports);
__exportStar(require("./transformer/to-ascii-lowercase"), exports);
