import { CommonModule } from '@angular/common';
import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Payload } from '../../classes/payload.model';

@Component({
  selector: 'app-payments',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.css'
})
export class PaymentsComponent implements OnInit{
  payments: any[] = [];
  paymentsSize: string;
  errorMessage: string;
  dataDisplayedFrom: any;
  dataDisplayedTo: any;
  currentPayments: any[];
  error: boolean = false;

  constructor(private element: ElementRef, private changeDetection: ChangeDetectorRef, private payload: Payload) {}

  ngOnInit(): void {
      try {
        this.payments = this.payload.getPayments();
        this.paymentsSize = this.getPaymentsSize().toString();
        this.currentPayments = this.payments.slice(0, 10);

        let btn = document.getElementById("pagination1");
        btn.addEventListener("click", () => this.changeTable());
        
      }
      catch {
        this.error = true;
        this.errorMessage = 'Something went wrong. Please try again later.';
      }    
    
  }

  ngAfterViewInit() {
    window['oneX']?.addElement(this.element.nativeElement);
  }

  ngOnDestroy() {
    window['oneX']?.removeElement(this.element.nativeElement);
  }

  getPaymentsSize() {
    return this.payments.length;
  }


  getSomePayments() {
    let pagination = document.getElementById('pagination1');
    this.dataDisplayedFrom = Number(pagination.children[0].children[1].attributes['data-displayed-from'].value);
    this.dataDisplayedTo = Number(pagination.children[0].children[1].attributes['data-displayed-to'].value);
    this.currentPayments = this.payments.slice((this.dataDisplayedFrom-1), this.dataDisplayedTo);

    return this.currentPayments;
  }

  changeTable() {
    this.getSomePayments();
    this.changeDetection.detectChanges();

  }

}
