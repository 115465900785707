<div class="-oneX-container">
    <div class="-oneX-row -oneX-justify-content-start -oneX-justify-content-start">
        <h5 tabindex="0" id="lossDet"><i>Contractor Comments</i></h5>
    </div>
    <div class="-oneX-row">
        <div class="-oneX-col-md-12 ">
            <label for="commentBox" class="-oneX-textfield__label">Enter information or questions regarding the claim and
                State Farm will be in contact with you within 3 business days. </label>
            <textarea id="commentBox" class="-oneX-textfield--conversational-input" [(ngModel)]="remainingChar"></textarea>
            <div class="-oneX-textarea-charRemaining">{{ charLimit - remainingChar.length || 0 }}/{{charLimit}} characters remaining</div>
            <div class="-oneX-textarea-sensitiveText">To protect sensitive personal information, do not include full
                SSN, TIN, SIN, DL#, financial account numbers, credit/debit card numbers, PHI or any medical information
            </div>
        </div>
    </div>
    <br>
    <div class="-oneX-row -oneX-justify-content-center">
        <button tabindex="0" id="commentBtn" class="-oneX-btn-primary -oneX-btn-fit-content"
        type="submit">Submit</button>
    </div>
</div>