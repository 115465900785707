<div class="-oneX-container">
    <div class="-oneX-container">
        <div class="-oneX-row">
            <div class="-oneX-heading--h1 topPadding">Claim Found</div>
        </div>
    </div>
    <hr>
    <div class="-oneX-container">
        <div class="-oneX-row">
            <div class="-oneX-container">   
                <app-loss-details></app-loss-details>     
            </div>
        </div>
        <hr>
    </div>
    <div class="-oneX-container">
        <div class="-oneX-row">
            <div class="-oneX-container">   
                <app-payments></app-payments>     
            </div>
        </div>
        <hr>
    </div>
    <div class="-oneX-container">
        <div class="-oneX-row">
            <div class="-oneX-container">   
                <app-claim-handler></app-claim-handler>     
            </div>
        </div>
        <hr>
    </div>
    <div class="-oneX-container">
        <div class="-oneX-row">
            <div class="-oneX-container">   
                <app-claim-information></app-claim-information>     
            </div>
        </div>
        <hr>
    </div>
    <br>
    <div class="-oneX-row -oneX-justify-content-center">
        <button tabindex="0" id="buttonid" class="-oneX-btn-primary -oneX-btn-fit-content"
            type="submit" (click)="goToSearch()">Search another claim</button>
    </div>
</div>