"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharacterIterator = void 0;
const Char_1 = require("./Char");
class CharacterIterator {
    constructor(input) {
        this.lastPosition = -1;
        this.currentPosition = 0;
        this._lastWidth = 0;
        this._input = input ?? '';
    }
    get input() {
        return this._input;
    }
    setInput(input) {
        this._input = input;
        this.reset();
        return this;
    }
    reset() {
        this.lastPosition = -1;
        this.currentPosition = 0;
        this._lastWidth = 0;
    }
    next() {
        if (this.done)
            return { done: true, value: undefined };
        this.lastPosition = this.currentPosition;
        const char = this._input.charCodeAt(this.currentPosition++);
        this._lastWidth = 1;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.done || !(0, Char_1.isHighSurrogate)(char))
            return { done: false, value: char };
        // Do we have a surrogate pair?
        const next = this._input.charCodeAt(this.currentPosition);
        if ((0, Char_1.isLowSurrogate)(next)) {
            this._lastWidth++;
            this.currentPosition++;
            return { done: false, value: (0, Char_1.convertSurrogatePairToCodePoint)(char, next) };
        }
        return { done: false, value: char };
    }
    // Position of the iterator; equals the start index of the last character consumed.
    // -1 if no characters were consumed yet.
    get position() {
        return this.lastPosition;
    }
    // Width of the last character consumed; 2 if it was a surrogate pair and 1 otherwise.
    // 0 if no characters were consumed yet.
    get lastWidth() {
        return this._lastWidth;
    }
    get done() {
        return this.currentPosition >= this._input.length;
    }
    [Symbol.iterator]() {
        return this;
    }
}
exports.CharacterIterator = CharacterIterator;
