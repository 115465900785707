<div class="-oneX-container">
    <div class="-oneX-heading--h3">Loss Details</div>
      <br>
        <div class="-oneX-container">
          <div class="-oneX-container">
            <div class="-oneX-row">
              <div tabindex="-1" class="-oneX-col-md-2 -oneX-col-lg-4" aria-labelledby="status">
                <div tabindex="0" id="statusField" class="-oneX-body--intro">Status:</div>
                <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1"*ngIf="!error">{{status}}</div>
                <div tabindex="0" id="status"> </div>
              </div>

              <div tabindex="-1" class="-oneX-col-md-2 -oneX-col-lg-4" aria-labelledby="street">
                <div tabindex="0" id="streetField" class="-oneX-body--intro">Loss Location:</div>
                <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1"*ngIf="!error">{{street}}</div>  
                <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1"*ngIf="!error">{{lossLocation}}</div>              
                <div tabindex="0" id="street"> </div>
              </div>
            </div>
        </div>
      </div>
  </div>