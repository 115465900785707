"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dictionary = void 0;
exports.dictionary = new Map([
    ['a', '@4'],
    ['c', '('],
    ['e', '3'],
    ['i', '1|!'],
    ['g', '6'],
    ['o', '0'],
    ['s', '$5'],
    ['t', '7'],
    ['z', '2'],
]);
