import { Component, OnInit } from '@angular/core';
import { ClaimsApiService } from '../../services/Claim/claims-api.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-claim-handler',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './claim-handler.component.html',
  styleUrl: './claim-handler.component.css'
})
export class ClaimHandlerComponent implements OnInit{
  name: string;
  phone: string;
  street: string;
  mailingAddress: string;
  fax: string;

  errorMessage: string;
  error: boolean;

  constructor(private api: ClaimsApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => { 
      try {
        this.name = data['httpResponse'].body.payload.claim_handler.name.first_name + ' ' + data['httpResponse'].body.payload.claim_handler.name.last_name;
        
        let phoneNumber = data['httpResponse'].body.payload.claim_handler.phone.number
        let phoneDash = phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3,7)
        this.phone = data['httpResponse'].body.payload.claim_handler.phone.area_code + '-' + phoneDash
        
        if(data['httpResponse'].body.payload.claim_handler.phone.extension!=""){
          this.phone = this.phone+' x' + data['httpResponse'].body.payload.claim_handler.phone.extension;
        } 

      }
      catch {
        this.error = true;
        this.errorMessage = 'Something went wrong. Please try again later.';
        data['httpResponse'].subscribe()
      }
      finally {
        let actualMailingAddress = {'street': 'P.O. Box 106169', 'city': 'Atlanta', 'state': 'GA', 'zip_code': '30348-6169'}
        let actualFax = {'country': 'US', 'area_code': '844', 'number': '2363646', 'extension': ""}

        this.street = actualMailingAddress.street
        this.mailingAddress = actualMailingAddress.city + ', ' + actualMailingAddress.state + ', ' + actualMailingAddress.zip_code;
        console.log(this.mailingAddress)
        let faxNumber = actualFax.number
        let faxDash = faxNumber.slice(0, 3) + '-' + faxNumber.slice(3,7)
        this.fax = actualFax.area_code + '-' + faxDash
        console.log(this.fax)
      }
    });
  }
}
