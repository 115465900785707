"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollapseDuplicatesTransformer = void 0;
class CollapseDuplicatesTransformer {
    constructor({ defaultThreshold, customThresholds }) {
        this.remaining = -1;
        this.lastChar = -1;
        this.defaultThreshold = defaultThreshold;
        this.customThresholds = customThresholds;
    }
    transform(char) {
        if (char === this.lastChar) {
            return this.remaining-- > 0 ? char : undefined;
        }
        const threshold = this.customThresholds.get(char) ?? this.defaultThreshold;
        this.remaining = threshold - 1;
        this.lastChar = char;
        return threshold > 0 ? char : undefined;
    }
    reset() {
        this.remaining = -1;
        this.lastChar = -1;
    }
}
exports.CollapseDuplicatesTransformer = CollapseDuplicatesTransformer;
