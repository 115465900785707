<div class="-oneX-container">
  <div class="-oneX-heading--h3">Requested Claim Information</div>
    <br>
      <div class="-oneX-container">
        <div class="-oneX-container">
          <div class="-oneX-row">
              <div tabindex="-1" class="-oneX-col-md-2 -oneX-col-lg-4" aria-labelledby="claimNumber">
                <div tabindex="0" id="claimNumberField" class="-oneX-body--intro" *ngIf="!error">Claim Number: </div>
                <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1"*ngIf="!error">{{claimNumber}}</div>
                <div tabindex="0" id="claimNumberField" *ngIf="error">{{errorMessage}}</div>
                <div tabindex="0" id="claimNumber"> </div>
              </div>

              <div tabindex="-1" class="-oneX-col-md-2 -oneX-col-lg-4" aria-labelledby="dateOfLoss">
                <div tabindex="0" id="dateOfLossField" class="-oneX-body--intro" *ngIf="!error">Date of Loss: </div>
                <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1"*ngIf="!error">{{dateOfLoss}}</div>
                <div tabindex="0" id="dateOfLossField" *ngIf="error">{{errorMessage}}</div>
                <div tabindex="0" id="dateOfLoss"> </div>
              </div>

              <div tabindex="-1" class="-oneX-col-md-2 -oneX-col-lg-4" aria-labelledby="name">
                <div tabindex="0" id="nameField" class="-oneX-body--intro" *ngIf="!error">Name:</div>
                <div tabindex="0" id="statusField" class="-oneX-body--intro-sm -oneX-typography-variant1 -oneX-offset-1"*ngIf="!error">{{name}}</div>
                <div tabindex="0" id="nameField" *ngIf="error">{{errorMessage}}</div>
                <div tabindex="0" id="name"> </div>
              </div>
          </div>
      </div>
    </div>
</div>