<div class="-oneX-container">
  <div class="-oneX-row -oneX-justify-content-start -oneX-justify-content-start">
    <h5 tabindex="0" id="lossDet"><i>Loss Details</i></h5>
    </div>
    <div class="-oneX-row">
      <div class="-oneX-col-md-4" tabindex="-1">
        <div tabindex="0" aria-labelledby="status"class="-oneX-header"id="statusField">Status:</div>
          <div tabindex="1" id="statusField" class="-oneX-details"*ngIf="!error">{{status}}</div>
          <!-- <div tabindex="0" id="status"> </div> -->
        </div>
      
        <div class="-oneX-col-md-4" tabindex="-1">
          <div tabindex="0" id="streetField" class="-oneX-header">Loss Location:</div>
          <div tabindex="0" id="statusField" class="-oneX-details"*ngIf="!error" style="white-space: pre-line;">{{address}}</div>
          <!-- <div tabindex="0" id="street"> </div> -->
        </div>      
            
      </div>
      
</div>