<div class="page-loader-modal" [hidden]="!loading">
    <div id="oneX-modal-1" class="-oneX -oneX-modal__container">
        <div class="-oneX-modal-content" aria-labelledby="myExampleId" role="dialog" style="outline: solid">
            <div class="-oneX-page-loader" style="display:inline">
                <h2 id="myExampleId" class="-oneX-modal-heading -oneX-loading-message" tabindex="-1">Loading...</h2>
                <div class="-oneX-loader-icon" aria-hidden="true"></div>

                <div class="-oneX-loaderdiv" aria-hidden="true">
                    <svg class="-oneX-small" viewBox="0 0 64 64">
                        <circle cx="32" cy="32" r="28.8"></circle>
                    </svg>

                    <svg class="-oneX-medium" viewBox="0 0 80 80">
                        <circle cx="40" cy="40" r="36"></circle>
                    </svg>

                    <svg class="-oneX-large" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="45"></circle>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>