import { Component } from '@angular/core';
import { SearchComponent } from '../../components/search/search.component';
import { ErrorComponent } from '../../components/error/error.component';

@Component({
  selector: 'app-search-page',
  standalone: true,
  imports: [SearchComponent, ErrorComponent],
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.css'
})
export class SearchPageComponent {

}
