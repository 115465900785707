<div class="-oneX-container">
  <div class="-oneX-row -oneX-justify-content-start -oneX-justify-content-start">
    <h5 tabindex="0" id="claimHandler"><i>Claim Handler</i></h5>
  </div>
        
  <div class="-oneX-row"> <!--Remove ngIf if don't want-->
    <div tabindex="-1" class="-oneX-col-md-4" aria-labelledby="name">
      <div tabindex="0" id="nameField" class="-oneX-header">Name: </div>
      <div tabindex="0" id="statusField" class="-oneX-details" >{{name}}</div> <!--Move ngIf here if want Name:-->
    </div>

    <div tabindex="-1" class="-oneX-col-md-4" aria-labelledby="phone">
      <div tabindex="0" id="phoneField" class="-oneX-header"> Phone: </div>
      <div tabindex="0" id="statusField" class="-oneX-details" *ngIf="!error">{{phone}}</div> <!--Move ngIf here if want Phone:-->
    </div>
  </div>
            <br>
            <div class="-oneX-row">
              <div tabindex="-1" class="-oneX-col-md-4" aria-labelledby="mailingAddress">
                <div tabindex="0" id="mailingAddressField" class="-oneX-header"> Mailing Address: </div>
                <div tabindex="0" id="statusField" class="-oneX-details" style="white-space: pre-line;">{{address}}</div>
                <!-- <div tabindex="0" id="mailingAddress"> </div> -->
              </div>

              <div tabindex="-1" class="-oneX-col-md-4" aria-labelledby="fax">
                <div tabindex="0" id="faxField" class="-oneX-header"> Fax: </div>
                <div tabindex="0" id="statusField" class="-oneX-details">{{fax}}</div>
                <!-- <div tabindex="0" id="fax"> </div> -->
              </div>
              </div>
      
  </div>