<div id="unAuthModalContainer" class="-oneX-modal__container" style="display:none" data-disabled-scrim>
    <div class="-oneX-modal-content " aria-labelledby="unAuthModalHeading" role="dialog" >
        <div id="unAuthModalHeading" class="-oneX-modal-heading " role="heading" aria-level="2" tabindex="-1">Do you have a B2B account? </div>
        <hr>
        <div class="-oneX-modal-body">
            <p>If you have a B2B account, please <a class="-oneX-link--inline" href="{{secureLogin}}" target="_blank" id="loginLink">log in </a> to access the Fire Claim View. </p>
            <p>If you don't have a B2B account, please fill out the <a class="-oneX-link--inline" href="{{contactUs}}" target="_blank" id="contactUsLink1">Contact Us </a> form and select the below options. </p>
            <ul>
                <li>Type of Business: <b>Fire Service Provider</b></li>
                <li>Reason for Contact: <b>Fire Claim View</b> </li>
            </ul>
        </div>
    </div>
</div>

<div id="forbiddenModalContainer" class="-oneX-modal__container" style="display:none" data-disabled-scrim>
    <div class="-oneX-modal-content" aria-labelledby="forbiddenModalHeading" role="dialog" style="text-align: center;">
        <div id="forbiddenModalHeading" class="-oneX-modal-heading" role="heading" aria-level="2" tabindex="-1">
            You do not have the correct access. 
        </div>
        <hr>
        <div class="-oneX-modal-body">
            <p>If you are a Fire Service Provider, fill out the <a class="-oneX-link--inline" href="{{contactUs}}" target="_blank" id="contactUsLink2">Contact Us </a> form and select the below options. </p>
            <ul>
                <li style="float:left">Type of Business: <b>Fire Service Provider</b></li>
                <li style="float:left">Reason for Contact: <b>Fire Claim View</b> </li>
            </ul>        
        </div>
    </div>
</div>