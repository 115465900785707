import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader-spinner',
  standalone: true,
  templateUrl: './loader-spinner.component.html',
  styleUrl: './loader-spinner.component.css'
})
export class LoaderSpinnerComponent implements OnInit {
  loading: boolean;

  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
       this.loading = v;
    });

  }
  ngOnInit(): void {
    this.loading;
  }
}
