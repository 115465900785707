import { Component, OnInit } from '@angular/core';
import { ClaimsApiService } from '../../services/Claim/claims-api.service';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-loss-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loss-details.component.html',
  styleUrl: './loss-details.component.css'
})
export class LossDetailsComponent implements OnInit{
  status: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  ext_id: string;
  lossLocation: string;
  errorMessage: string;
  error: boolean;

  constructor(private api: ClaimsApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => { 
      try {
        //Example for getting the error regarding loss location
        /*
        if (JSON.stringify(data['httpResponse'].body.payload.loss_location) === '{}') {
          let errorsArray = data['httpResponse'].body.errors
          console.log(errorsArray)
          errorsArray.forEach(element => {
            if (element.code === "Could not retrieve loss location") {
              this.errorMessage = element.code
            }
            else {
              this.errorMessage = 'Something went wrong. Please try again later.';
            }
          });
          this.error = true;
        }
        else {}
        */
        this.status = data['httpResponse'].body.payload.status;

        this.street = data['httpResponse'].body.payload.loss_location.street;
        this.city = data['httpResponse'].body.payload.loss_location.city;
        this.state = data['httpResponse'].body.payload.loss_location.state;
        this.zipCode = data['httpResponse'].body.payload.loss_location.zip_code;
        this.lossLocation = this.city + ', ' + this.state + ', ' + this.zipCode;
        
        
      }
      catch{ 
        this.error = true;
        this.errorMessage = 'Something went wrong. Please try again later.';
      }
    }
  );
  }
}